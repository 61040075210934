<template>
  <div class="av-fido-manager">
    <vl-title tag-name="h2">
      {{ $t('fido-manager.title') }}
    </vl-title>
    <vl-grid>
      <vl-column
        width-l="8"
        width-m="6"
        width-s="6"
        width-xs="12"
      >
        <vl-grid mod-stacked>
          <vl-column>
            <vl-form-message-label>
              {{ $t('fido-manager.use-fido') }}
            </vl-form-message-label>
            <vl-form-message-annotation>
              {{ $t('fido-manager.use-fido-annotation') }}
            </vl-form-message-annotation>


            <div class="vl-checkbox--switch__wrapper">
              <input
                id="fido-switch"
                v-model="isActiveToggle"
                class="vl-checkbox--switch"
                type="checkbox"
                name="fido-switch"
                role="switch"
                :disabled="!isPasskeyAvailable"
                @input="event => toggleActive(event.target.checked)"
              >
              <label
                class="vl-checkbox__label"
                for="fido-switch"
              >
                <span class="vl-checkbox--switch__label">
                  <span aria-hidden="true" />
                </span>
                <span>{{ isActive ? $t('generic.active') : $t('generic.inactive') }}</span>
              </label>
            </div>
          </vl-column>
          <vl-column>
            <av-fido-device-manager v-if="isActive" />
          </vl-column>
        </vl-grid>
      </vl-column>
      <vl-column
        width-l="4"
        width-m="6"
        width-s="6"
        width-xs="12"
      >
        <vl-info-tile
          tag-name="div"
          :title="$t('fido-manager.help-title')"
        >
          <vl-badge
            slot="badge"
            icon="question-mark"
            mod-accent
          />
          {{ $t('fido-manager.help-text') }}
        </vl-info-tile>
      </vl-column>
    </vl-grid>
  </div>
</template>

<script>
import {
  VlBadge,
  VlFormMessageAnnotation,
  VlFormMessageLabel,
  VlInfoTile,
  VlTitle
} from '@govflanders/vl-ui-vue-components'
import {mapActions, mapState} from "vuex";
import AvFidoDeviceManager from "@/components/molecules/AvFidoDeviceManager";

export default {
  name: "AvFidoManager",
  components: {
    AvFidoDeviceManager,
    VlTitle,
    VlFormMessageAnnotation,
    VlFormMessageLabel,
    VlInfoTile,
    VlBadge,
  },
  data() {
    return {
      internalIsActiveValue: undefined,
    };
  },
  computed: {
    ...mapState({
      isActive: st => st.fidoManager.isFidoActive,
      isPasskeyAvailable: st => st.mailManager.isEmailValidated || st.features.isLeerIDManagerEnabled,
    }),
    isActiveToggle: {
      get() {
        return this.isActive
      },
      set(value) {
        this.toggleActive(value)
      },
    },
  },
  async mounted() {
    await this.loadSettings();
  },
  methods: {
    ...mapActions({
      updateFidoActivation: 'fidoManager/updateFidoActivation',
      loadSettings: 'fidoManager/fetchUserSettings'
    }),
    toggleActive(value) {
      if (value !== this.internalIsActiveValue) {
        this.internalIsActiveValue = value;
        this.updateFidoActivation(value);
      }
    }
  }
}
</script>

<style scoped>
.vl-checkbox--switch__wrapper {
  margin-top: 7px;
}
</style>

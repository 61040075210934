<template>
  <vl-modal
    :id="id"
    closable
  >
    <vl-grid mod-stacked>
      <vl-column>
        {{ $t('fido-manager.confirm-remove', {device: device.label}) }}
      </vl-column>
      <vl-column>
        <vl-action-group>
          <vl-button
            v-vl-modal-toggle="id"
            mod-naked-action
          >
            {{ $t('generic.cancel') }}
          </vl-button>
          <vl-button
            v-vl-modal-toggle="id"
            @click="deleteDevice"
          >
            {{ $t('generic.ok') }}
          </vl-button>
        </vl-action-group>
      </vl-column>
    </vl-grid>
  </vl-modal>
</template>

<script>
import {VlActionGroup, VlButton, VlModal} from '@govflanders/vl-ui-vue-components'

import {mapActions} from "vuex";

export default {
  name: "AvRemoveDeviceModal",
  components: {
    VlButton,
    VlModal,
    VlActionGroup
  },
  props: {
    device: {
      default: undefined,
      type: Object,
    },
    id: {
      default: 'remove-modal',
      type: String,
    }
  },
  methods: {
    ...mapActions({
      deleteDeviceFromStore: 'fidoManager/removeRegisteredDevice',
    }),
    deleteDevice() {
      this.deleteDeviceFromStore(this.device);
    }
  }
}
</script>

<style scoped>

</style>

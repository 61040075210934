<template>
  <vl-modal
    id="register-modal"
    :title="$t('fido-manager.register-new-device')"
  >
    <vl-grid mod-stacked>
      <vl-column>
        <av-browser-check-card />
      </vl-column>
      <vl-column
        v-if="!isFidoUnsupported"
      >
        <vl-form-validation-observer
          v-slot="{ handleSubmit }"
          slim
        >
          <form @submit.prevent="handleSubmit(registerThisDevice)">
            <vl-grid mod-stacked>
              <vl-column>
                <vl-grid mod-stacked>
                  <vl-column>
                    <vl-grid mod-stacked-small>
                      <vl-column>
                        <vl-form-message-label>{{ $t('fido-manager.select-icon') }}</vl-form-message-label>
                        <vl-form-message-annotation>
                          {{
                            $t('fido-manager.select-icon-intro')
                          }}
                        </vl-form-message-annotation>
                      </vl-column>
                      <vl-column>
                        <av-icon-selector v-model="deviceIcon" />
                      </vl-column>
                    </vl-grid>
                  </vl-column>
                  <vl-column>
                    <vl-form-message-label>{{ $t('fido-manager.device-name') }}</vl-form-message-label>
                    <vl-form-message-annotation>{{ $t('fido-manager.device-name-intro') }}</vl-form-message-annotation>
                    <vl-form-validation
                      v-slot="{ errors: fieldErrors, classes }"
                      rules="required"
                      :name="$t('fido-manager.device-name')"
                    >
                      <vl-form-message-error v-if="fieldErrors">
                        {{ fieldErrors[0] }}
                      </vl-form-message-error>
                      <vl-input-field
                        id="device-name-input"
                        v-model="deviceName"
                        :class="classes"
                        type="text"
                        name="device-name-input"
                        mod-block
                      />
                    </vl-form-validation>
                  </vl-column>
                </vl-grid>
              </vl-column>
              <vl-column>
                <vl-action-group>
                  <vl-button>
                    {{ $t('fido-manager.start-registration') }}
                  </vl-button>
                  <vl-button
                    v-vl-modal-toggle="'register-modal'"
                    mod-naked-action
                  >
                    {{ $t('generic.cancel') }}
                  </vl-button>
                </vl-action-group>
              </vl-column>
            </vl-grid>
          </form>
        </vl-form-validation-observer>
      </vl-column>
      <vl-column
        v-else
      >
        <vl-button
          v-vl-modal-toggle="'register-modal'"
        >
          {{ $t('fido-manager.browser-check.understand') }}
        </vl-button>
      </vl-column>
    </vl-grid>
  </vl-modal>
</template>

<script>
import {
  VlActionGroup,
  VlButton,
  VlFormMessageAnnotation,
  VlFormMessageError,
  VlFormMessageLabel,
  VlFormValidation,
  VlFormValidationObserver,
  VlInputField,
  VlModal,
} from '@govflanders/vl-ui-vue-components'
import AvIconSelector from "@/components/atoms/AvIconSelector";
import AvBrowserCheckMixin from "@/components/mixins/av-browser-check-mixin";
import AvBrowserCheckCard from "@/components/atoms/AvBrowserCheckCard";

import {mapActions} from "vuex";

export default {
  name: "AvRegisterDeviceModal",
  components: {
    AvIconSelector,
    AvBrowserCheckCard,
    VlButton,
    VlFormMessageAnnotation,
    VlFormMessageLabel,
    VlModal,
    VlActionGroup,
    VlFormValidation,
    VlFormMessageError,
    VlInputField,
    VlFormValidationObserver
  },
  mixins: [AvBrowserCheckMixin],
  data() {
    return {
      deviceName: '',
      deviceIcon: ''
    };
  },
  methods: {
    ...mapActions({
      saveDevice: 'fidoManager/addRegisteredDevice',
    }),
    registerThisDevice() {
      this.$root.$emit('modal-toggle', 'register-modal');

      this.saveDevice({
        label: this.deviceName,
        icon: this.deviceIcon,
      });

      this.deviceName = '';
      this.deviceIcon = '';
    },
  },
}
</script>

<style scoped>

</style>

import Vue from 'vue';
import router from './router';
import i18n from './i18n';
import App from './App.vue';
import Vuex from 'vuex';
import store from './store';

import {
  VlCore,
  VlModalToggle,
  VlUtil,
} from '@govflanders/vl-ui-vue-components';

Vue.use(VlCore);
Vue.use(VlUtil);
Vue.use(Vuex);
Vue.directive('vl-modal-toggle', VlModalToggle);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App),
  store: new Vuex.Store(store),
}).$mount('#app');

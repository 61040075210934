<template>
  <div class="av-multi-factor-manager">
    <vl-title tag-name="h3">
      {{ $t('multi-factor-manager.title') }}
    </vl-title>
    <vl-grid mod-stacked>
      <vl-column
        width-l="8"
        width-m="6"
        width-s="6"
        width-xs="12"
      >
        <vl-grid mod-stacked>
          <vl-column>
            <vl-form-message-label>
              {{ $t('multi-factor-manager.use-two-step-verification-email-title') }}
            </vl-form-message-label>
            <vl-form-message-annotation>
              {{ $t('multi-factor-manager.use-two-step-verification-email-annotation') }}
            </vl-form-message-annotation>

            <div class="vl-checkbox--switch__wrapper">
              <input
                id="multi-factor-switch"
                class="vl-checkbox--switch"
                type="checkbox"
                name="multi-factor-switch"
                role="switch"
                :value="isTwoStepVerificationActive"
                :disabled="!isEmailValidated"
                @input="event => toggleActive(event.target.checked)"
              >
              <label
                class="vl-checkbox__label"
                for="multi-factor-switch"
              >
                <span class="vl-checkbox--switch__label">
                  <span aria-hidden="true" />
                </span>
                <span>{{ isTwoStepVerificationActive ? $t('generic.active') : $t('generic.inactive') }}</span>
              </label>
            </div>
          </vl-column>
          <vl-column>
            <vl-form-message-label>
              {{ $t('multi-factor-manager.use-two-step-verification-app-title') }}
            </vl-form-message-label>
            <vl-form-message-annotation>
              {{ $t('multi-factor-manager.use-two-step-verification-app-annotation') }}
            </vl-form-message-annotation>

            <div class="vl-checkbox--switch__wrapper">
              <input
                id="multi-factor-switch"
                class="vl-checkbox--switch"
                type="checkbox"
                name="multi-factor-switch"
                role="switch"
                :value="isTwoStepVerificationActive"
                :disabled="!isEmailValidated"
                @input="event => toggleActive(event.target.checked)"
              >
              <label
                class="vl-checkbox__label"
                for="multi-factor-switch"
              >
                <span class="vl-checkbox--switch__label">
                  <span aria-hidden="true" />
                </span>
                <span>{{ isTwoStepVerificationActive ? $t('generic.active') : $t('generic.inactive') }}</span>
              </label>
            </div>
          </vl-column>
        </vl-grid>
      </vl-column>
      <vl-column
        width-l="4"
        width-m="6"
        width-s="6"
        width-xs="12"
      >
        <vl-info-tile
          tag-name="div"
          :title="$t('multi-factor-manager.help-title')"
        >
          <vl-badge
            slot="badge"
            icon="question-mark"
            mod-accent
          />
          {{ $t('multi-factor-manager.help-text') }}
        </vl-info-tile>
      </vl-column>
    </vl-grid>
  </div>
</template>

<script>
import {
  VlBadge,
  VlFormMessageAnnotation,
  VlFormMessageLabel,
  VlInfoTile,
  VlTitle,
} from '@govflanders/vl-ui-vue-components'
import {mapActions, mapState} from "vuex";

export default {
  name: "AvMultiFactorManager",
  components: {
    VlTitle,
    VlFormMessageLabel,
    VlFormMessageAnnotation,
    VlInfoTile,
    VlBadge,
  },
  data() {
    return {
      password: 'forshowintheinput',
      currentPassword: '',
      newPassword: '',
      isInPasswordEditMode: false,
      isPasswordValid: false,
    }
  },
  computed: {
    ...mapState({
      isTwoStepVerificationActive: st => st.studentAccountManager.isTwoStepVerificationActive,
      isEmailValidated: st => st.mailManager.isEmailValidated
    })
  },
  methods: {
    ...mapActions({
      activateTwoStepVerification: 'studentAccountManager/activateTwoStepVerification',
      deactivateTwoStepVerification: 'studentAccountManager/deactivateTwoStepVerification',
      savePassword: 'studentAccountManager/saveNewPassword'
    }),
    toggleActive(value) {
      if (value) {
        this.activateTwoStepVerification();
      } else {
        this.deactivateTwoStepVerification();
      }
    },
    saveNewPassword() {
      this.savePassword({newValue: this.newPassword, oldValue: this.currentPassword});
    }
  }
}
</script>

<style scoped>
.vl-checkbox--switch__wrapper {
  margin-top: 7px;
}

.vl-input-field {
  max-width: 500px;
  width: 100%;
}

.vl-button {
  margin-right: 20px;
}
</style>

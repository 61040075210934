<template>
  <div class="av-email-manager">
    <vl-form-validation-observer
      v-slot="{ handleSubmit }"
      slim
    >
      <form @submit.prevent="handleSubmit(validateEmail)">
        <vl-title tag-name="h3">
          {{ $t('mail-manager.sign-in-data') }}
        </vl-title>
        <vl-grid mod-stacked>
          <vl-column>
            <label
              class="vl-form__label"
              for="mail-input"
            >{{ $t('mail-manager.email') }}
            </label>
            <div
              v-if="isEmailValidated"
              class="av-email-manager__label-annotation"
            >
              {{ $t('mail-manager.validated') }}
            </div>

            <vl-form-validation
              v-slot="{ errors: fieldErrors, classes }"
              rules="required|email"
              name="email"
            >
              <vl-form-message-error v-if="fieldErrors">
                {{ fieldErrors[0] }}
              </vl-form-message-error>
              <vl-input-field
                id="mail-input"
                v-model="email"
                :class="classes"
                type="email"
                name="mail-input"
                :mod-disabled="isEmailValidated || isEmailValidationOngoing || changeEmailInProgress"
                mod-block
              />
            </vl-form-validation>
          </vl-column>
          <vl-column v-if="changeEmailInProgress">
            <label
              class="vl-form__label"
              for="new-mail-input"
            >{{ $t('mail-manager.new-email') }}
            </label>
            <vl-form-validation
              v-slot="{ errors: fieldErrors, classes }"
              rules="required|email"
              name="email"
            >
              <vl-form-message-error v-if="fieldErrors">
                {{ fieldErrors[0] }}
              </vl-form-message-error>
              <vl-input-field
                id="new-mail-input"
                v-model="newEmail"
                :class="classes"
                type="input"
                name="mail-input"
                :mod-disabled="isEmailValidationOngoing"
                mod-block
              />
            </vl-form-validation>
          </vl-column>
          <template v-if="isEmailValidated && !changeEmailInProgress">
            <vl-column>
              <vl-button
                type="button"
                @click="changeEmail"
              >
                {{ $t('mail-manager.change-email') }}
              </vl-button>
            </vl-column>
          </template>
          <template v-else>
            <template v-if="isEmailValidationOngoing">
              <vl-column>
                <vl-alert
                  icon="warning"
                  :title="$t('mail-manager.validation-mail-sent')"
                  mod-naked
                  role="alertdialog"
                >
                  <p class="vl-u-mark--accent">
                    {{ $t('mail-manager.validation-mail-sent-intro', {email: newEmail || email}) }}
                  </p>
                </vl-alert>
              </vl-column>
              <vl-column>
                <vl-action-group>
                  <vl-button
                    v-if="!emailResent"
                    type="button"
                    @click="resendEmail"
                  >
                    {{ $t('mail-manager.resend-email') }}
                  </vl-button>
                  <vl-button
                    v-else
                    mod-disabled
                    type="button"
                  >
                    {{ $t('mail-manager.email-resent') }}
                  </vl-button>
                  <vl-button
                    mod-secondary
                    type="button"
                    @click="changeEmail"
                  >
                    {{ $t('mail-manager.change-email') }}
                  </vl-button>
                </vl-action-group>
              </vl-column>
            </template>
            <template v-else>
              <vl-column>
                <vl-alert
                  icon="warning"
                  :title="$t('mail-manager.validate')"
                  mod-naked
                  mod-warning
                  role="alertdialog"
                >
                  <p class="vl-u-mark--warning">
                    {{ $t('mail-manager.validate-intro') }}
                  </p>
                </vl-alert>
              </vl-column>
              <vl-column>
                <vl-button>
                  {{ $t('mail-manager.start-validation') }}
                </vl-button>
              </vl-column>
            </template>
          </template>
        </vl-grid>
      </form>
    </vl-form-validation-observer>
  </div>
</template>

<script>
import {
  VlActionGroup,
  VlAlert,
  VlButton,
  VlFormMessageError,
  VlFormValidation,
  VlFormValidationObserver,
  VlInputField,
  VlTitle
} from '@govflanders/vl-ui-vue-components'
import {mapActions, mapState} from "vuex";

export default {
  name: "AvEmailManager",
  components: {
    VlAlert,
    VlButton,
    VlTitle,
    VlActionGroup,
    VlFormValidation,
    VlFormMessageError,
    VlInputField,
    VlFormValidationObserver
  },
  data() {
    return {
      emailInput: null,
      newEmailInput: null,
      changeEmailInProgress: false,
      emailResent: false,
    };
  },
  computed: {
    ...mapState({
      isEmailValidated: st => st.mailManager.isEmailValidated,
      isEmailValidationOngoing: st => st.mailManager.isEmailValidationOngoing,
      currentEmail: st => st.user.email,
      toBeValidatedEmail: st => st.mailManager.toBeValidatedEmail,
    }),
    email: {
      get() {
        return this.emailInput || this.currentEmail
      },
      set(value) {
        if (value !== this.emailInput) {
          this.emailInput = value;
        }
      }
    },
    newEmail: {
      get() {
        return this.newEmailInput || this.toBeValidatedEmail
      },
      set(value) {
        if (value !== this.newEmailInput) {
          this.newEmailInput = value;
        }
      }
    }
  },
  methods: {
    ...mapActions({
      startEmailValidation: 'mailManager/startEmailValidation',
      pauseEmailValidation: 'mailManager/pauseEmailValidation',
      resendValidationEmail: 'mailManager/resendValidationEmail'
    }),
    validateEmail(errors) {
      if (!errors || !errors.email) {
        this.startEmailValidation(this.newEmail || this.email);
      }
    },
    resendEmail() {
      this.resendValidationEmail();

      this.emailResent = true;
      setTimeout(() => this.emailResent = false, 5000);
    },
    changeEmail() {
      this.newEmail = null;
      this.changeEmailInProgress = true;
      this.pauseEmailValidation();
    }
  }
}
</script>

<style scoped lang="scss">
.av-email-manager {
  max-width: 500px;
  width: 100%;
}

.av-email-manager__label-annotation {
  font-size: 14px;
  line-height: 20px;
  color: #6A7686;
}
</style>

import { bufferDecode, bufferEncode } from '../helpers/buffer-coding';

const handleGenericError = (error, dispatch, key) => {
  console.error(error);

  if (!error || error.name !== 'NotAllowedError') {
    dispatch(
      'notifications/addNotification',
      {
        title: 'generic.error-title',
        key,
        type: 'error',
        content: 'generic.error-intro',
      },
      { root: true },
    );
  }
};

export default {
  namespaced: true,
  state: () => ({
    isFidoActive: false,
    isAutomaticFidoUseAllowed: false,
    registeredDevices: [],
  }),
  mutations: {
    setIsFidoActive(state, value) {
      state.isFidoActive = value;
    },
    setIsAutomaticFidoUseAllowed(state, value) {
      state.isAutomaticFidoUseAllowed = value;
    },
    setRegisteredDevices(state, value) {
      state.registeredDevices = value;
    },
  },
  actions: {
    updateFidoActivation({ commit, state }, isActive) {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user/settings`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          useFidoByDefault: state.isAutomaticFidoUseAllowed,
          fidoEnabled: isActive,
          twoFactorEnabled: false,
        }),
      })
        .then(() => {
          commit('setIsFidoActive', isActive);
        })
        .catch(error => {
          console.error(error);
        });
    },
    updateAutomaticUsePermission({ commit, state }, permission) {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user/settings`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          useFidoByDefault: permission,
          fidoEnabled: state.isFidoActive,
          twoFactorEnabled: false,
        }),
      })
        .then(() => {
          commit('setIsAutomaticFidoUseAllowed', permission);
        })
        .catch(error => {
          console.error(error);
          commit('setIsAutomaticFidoUseAllowed', !permission);
        });
    },
    async fetchRegisteredDevices({ commit, dispatch }) {
      dispatch('notifications/clearNotificationsForKey', 'fido-register-get', {
        root: true,
      });

      await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user/devices`, {
        credentials: 'include',
      })
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(data => {
                if (data) {
                  commit(
                    'setRegisteredDevices',
                    data.devices &&
                      data.devices.map(device => ({
                        id: device.id,
                        label: device.name,
                        registeredAt: new Date(device.createdAt).toLocaleString(
                          'nl-BE',
                        ),
                        icon: device.type,
                      })) || [],
                  );
                }
              })
              .catch(error =>
                handleGenericError(error, dispatch, 'fido-register-get'),
              );
          }
        })
        .catch(error =>
          handleGenericError(error, dispatch, 'fido-register-get'),
        );
    },
    addRegisteredDevice({ dispatch }, device) {
      dispatch('notifications/clearNotificationsForKey', 'fido-register', {
        root: true,
      });

      fetch(
        `${process.env.VUE_APP_API_BASE_URL}/api/user/devices/register/begin`,
        {
          credentials: 'include',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            deviceName: device.label,
            deviceType: device.icon,
          }),
        },
      )
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(data => {
                if (data) {
                  const keyInput = { ...data.publicKey };

                  keyInput.challenge = bufferDecode(data.publicKey.challenge);
                  keyInput.user.id = bufferDecode(data.publicKey.user.id);

                  return navigator.credentials.create({
                    publicKey: keyInput,
                  });
                }
              })
              .then(credential => {
                fetch(
                  `${process.env.VUE_APP_API_BASE_URL}/api/user/devices/register/finish`,
                  {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      id: credential.id,
                      rawId: bufferEncode(credential.rawId),
                      type: credential.type,
                      response: {
                        attestationObject: bufferEncode(
                          credential.response.attestationObject,
                        ),
                        clientDataJSON: bufferEncode(
                          credential.response.clientDataJSON,
                        ),
                      },
                    }),
                  },
                )
                  .then(response => {
                    if (response.ok) {
                      dispatch('fetchRegisteredDevices');
                      dispatch(
                        'notifications/addNotification',
                        {
                          title: 'fido-manager.registration-success-title',
                          key: 'fido-register',
                          type: 'success',
                          content: 'fido-manager.registration-success-intro',
                          hideAfter: 5000,
                        },
                        { root: true },
                      );
                    } else {
                      dispatch(
                        'notifications/addNotification',
                        {
                          title: 'fido-manager.registration-error-title',
                          key: 'fido-register',
                          type: 'error',
                          content: 'fido-manager.registration-error-intro',
                        },
                        { root: true },
                      );
                    }
                  })
                  .catch(error =>
                    handleGenericError(error, dispatch, 'fido-register'),
                  );
              })
              .catch(error =>
                handleGenericError(error, dispatch, 'fido-register'),
              );
          }
        })
        .catch(error => handleGenericError(error, dispatch, 'fido-register'));
    },
    removeRegisteredDevice({ commit, state, dispatch }, device) {
      dispatch('notifications/clearNotificationsForKey', 'fido-delete-device', {
        root: true,
      });

      fetch(
        `${process.env.VUE_APP_API_BASE_URL}/api/user/devices/${device.id}`,
        {
          credentials: 'include',
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
        .then(response => {
          if (response.ok) {
            commit('setRegisteredDevices', [
              ...state.registeredDevices.filter(el => el.id !== device.id),
            ]);

            dispatch(
              'notifications/addNotification',
              {
                title: 'fido-manager.remove-success-title',
                key: 'fido-register',
                type: 'success',
                content: 'fido-manager.remove-success-intro',
                hideAfter: 5000,
              },
              { root: true },
            );
          }
        })
        .catch(error =>
          handleGenericError(error, dispatch, 'fido-delete-device'),
        );
    },
    test({ dispatch }) {
      dispatch('notifications/clearNotificationsForKey', 'fido-test', {
        root: true,
      });

      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user/test/begin`, {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then(response => {
          if (response.ok) {
            let sessionId;
            response
              .json()
              .then(data => {
                if (data) {
                  sessionId = data.sessionId;

                  const keyInput = { ...data.publicKey };
                  keyInput.challenge = bufferDecode(data.publicKey.challenge);
                  keyInput.allowCredentials.forEach(listItem => {
                    listItem.id = bufferDecode(listItem.id);
                  });

                  return navigator.credentials.get({
                    publicKey: keyInput,
                  });
                } else {
                  throw new Error({
                    message: 'Data not found',
                  });
                }
              })
              .then(assertion => {
                fetch(
                  `${process.env.VUE_APP_API_BASE_URL}/api/user/test/finish`,
                  {
                    credentials: 'include',
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      sessionId,
                      id: assertion.id,
                      rawId: bufferEncode(assertion.rawId),
                      type: assertion.type,
                      response: {
                        authenticatorData: bufferEncode(
                          assertion.response.authenticatorData,
                        ),
                        clientDataJSON: bufferEncode(
                          assertion.response.clientDataJSON,
                        ),
                        signature: bufferEncode(assertion.response.signature),
                        userHandle: bufferEncode(assertion.response.userHandle),
                      },
                    }),
                  },
                )
                  .then(response => {
                    if (response.ok) {
                      dispatch(
                        'notifications/addNotification',
                        {
                          title: 'fido-manager.test-fido-success-title',
                          key: 'fido-test',
                          type: 'success',
                          content: 'fido-manager.test-fido-success-intro',
                          hideAfter: 5000,
                        },
                        { root: true },
                      );
                    } else {
                      dispatch(
                        'notifications/addNotification',
                        {
                          title: 'fido-manager.test-fido-error-title',
                          key: 'fido-test',
                          type: 'error',
                          content: 'fido-manager.test-fido-error-intro',
                        },
                        { root: true },
                      );
                    }
                  })
                  .catch(error =>
                    handleGenericError(error, dispatch, 'fido-test'),
                  );
              })
              .catch(error => handleGenericError(error, dispatch, 'fido-test'));
          }
        })
        .catch(error => handleGenericError(error, dispatch, 'fido-test'));
    },
    async fetchUserSettings({ commit, dispatch }) {
      dispatch('notifications/clearNotificationsForKey', 'fido-settings', {
        root: true,
      });

      await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user/settings`, {
        credentials: 'include',
      })
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(data => {
                if (data) {
                  commit('setIsFidoActive', data.fidoEnabled);
                  commit('setIsAutomaticFidoUseAllowed', data.useFidoByDefault);
                }
              })
              .catch(error =>
                handleGenericError(error, dispatch, 'fido-settings'),
              );
          }
        })
        .catch(error => handleGenericError(error, dispatch, 'fido-settings'));
    },
  },
  getters: {},
};

<template>
  <vl-grid mod-stacked-small>
    <vl-column>
      <vl-form-message-label>
        {{ $t('fido-manager.test-fido') }}
      </vl-form-message-label>
      <vl-form-message-annotation>
        {{ $t('fido-manager.test-fido-intro') }}
      </vl-form-message-annotation>
    </vl-column>
    <vl-column>
      <vl-button
        mod-secondary
        @click="startTest"
      >
        {{ $t('fido-manager.start-fido-test') }}
      </vl-button>
    </vl-column>
  </vl-grid>
</template>

<script>
import {VlButton, VlFormMessageAnnotation, VlFormMessageLabel} from '@govflanders/vl-ui-vue-components'
import {mapActions} from "vuex";

export default {
  name: "AvFidoTest",
  components: {
    VlFormMessageLabel,
    VlFormMessageAnnotation,
    VlButton,
  },
  methods: {
    ...mapActions({
      startFidoTest: 'fidoManager/test',
    }),
    startTest() {
      this.startFidoTest();
    }
  }
}
</script>

<style scoped>

</style>

export default {
  data() {
    return {
      isFidoWithPlatformAuthUnsupportedResult: false,
    };
  },
  mounted() {
    window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then(
      value => (this.isFidoWithPlatformAuthUnsupportedResult = !value),
    );
  },
  computed: {
    isFidoUnsupported() {
      return !window.PublicKeyCredential;
    },
    isFidoWithPlatformAuthUnsupported() {
      return this.isFidoWithPlatformAuthUnsupportedResult;
    },
  },
};

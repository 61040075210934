<template>
  <vl-toaster
    mod-top-right
    fade-out
  >
    <vl-alert
      v-for="(notification, index) of notifications"
      :key="index"
      mod-small
      :mod-error="notification.type === 'error'"
      :mod-success="notification.type === 'success'"
      :mod-warning="notification.type === 'warning'"
      closable
      :title="$t(notification.title)"
      :content="$t(notification.content)"
      @close="() => close(notification)"
    />
  </vl-toaster>
</template>

<script>
import {VlAlert, VlToaster} from '@govflanders/vl-ui-vue-components'
import {mapActions, mapState} from "vuex";

export default {
  name: "AvToaster",
  components: {
    VlAlert,
    VlToaster,
  },
  computed: {
    ...mapState({
      notifications: st => st.notifications.list
    })
  },
  methods: {
    ...mapActions({
      removeNotification: 'notifications/removeNotification'
    }),
    close(notification) {
      this.removeNotification(notification);
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="av-device-card">
    <vl-info-tile
      tag-name="div"
      :title="device.label"
      :subtitle="$t('fido-manager.registered-on', {date: device.registeredAt})"
      mod-alt
    >
      <vl-badge
        slot="badge"
        :icon="device.icon"
        mod-white
        mod-border
      />

      <vl-button
        v-vl-modal-toggle="`remove${device.id}`"
        mod-error
        mod-secondary
      >
        {{ $t('generic.delete') }}
      </vl-button>
    </vl-info-tile>
    <av-remove-device-modal
      :id="`remove${device.id}`"
      :device="device"
    />
  </div>
</template>

<script>
import {VlBadge, VlButton, VlInfoTile} from '@govflanders/vl-ui-vue-components'
import {mapActions} from "vuex";
import AvRemoveDeviceModal from "@/components/molecules/AvRemoveDeviceModal";

export default {
  name: "AvDeviceCard",
  components: {
    AvRemoveDeviceModal,
    VlInfoTile,
    VlBadge,
    VlButton
  },
  props: {
    device: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions({
      deleteDeviceFromStore: 'fidoManager/removeRegisteredDevice',
    }),
    deleteDevice() {
      this.deleteDeviceFromStore(this.device);
    }
  }
}
</script>

<style scoped>
.av-device-card {
  max-width: 320px;
  width: 100%;
}
</style>

<template>
  <vl-action-group class="av-icon-selector">
    <vl-button
      v-for="icon of possibleIcons"
      :key="icon"
      mod-naked
      mod-narrow
      class="av-icon-selector__element"
      @click.prevent="selectedIcon = icon"
    >
      <vl-badge
        mod-medium
        mod-notification-bottom
        mod-notification-right
        mod-border
        mod-alt
        :icon="icon"
      >
        <template v-slot:notification>
          <vl-badge
            v-if="selectedIcon === icon"
            icon="check"
            mod-success
          />
        </template>
      </vl-badge>
    </vl-button>
  </vl-action-group>
</template>

<script>
import {VlActionGroup, VlBadge, VlButton} from '@govflanders/vl-ui-vue-components'

export default {
  name: "AvIconSelector",
  components: {
    VlBadge,
    VlButton,
    VlActionGroup
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      possibleIcons: [
        'laptop',
        'mobile-phone',
        'computer-screen',
        'face-id',
        'fingerprint',
        'usb',
      ],
    };
  },
  computed: {
    selectedIcon: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    }
  },
  mounted() {
    this.selectedIcon = this.possibleIcons[0];
  }
}
</script>

<style scoped>
.av-icon-selector__element {
  padding: 0;
  cursor: pointer;
}
</style>

<template>
  <div class="av-password-field">
    <label
      class="vl-form__label"
      :for="id"
    >{{ label }}
    </label>
    <vl-input-group>
      <input
        :id="id"
        :value="value"
        class="vl-input-field vl-input-field--block"
        :type="isShown? 'text' : 'password'"
        name="id"
        @input="passwordChanged"
      >
      <vl-input-addon
        tag-name="button"
        type="button"
        icon="view-add"
        :tooltip="$t(isShown ?'password.hide' : 'password.show')"
        :text="$t(isShown ?'password.hide' : 'password.show')"
        @click="isShown = !isShown"
      />
    </vl-input-group>
  </div>
</template>

<script>
import {VlInputAddon, VlInputGroup} from '@govflanders/vl-ui-vue-components'

export default {
  name: "AvPasswordField",
  components: {
    VlInputGroup,
    VlInputAddon
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      isShown: false,
    }
  },
  methods: {
    passwordChanged(event) {
      this.$emit('input', event?.target?.value);
    }
  }
}
</script>

<style scoped>
.av-password-field {
  max-width: 500px;
  width: 100%;
}
</style>

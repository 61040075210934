export default {
  namespaced: true,
  state: () => ({
    email: null,
    username: null,
    isUserAvailable: false,
  }),
  mutations: {
    setUserEmail(state, email) {
      state.email = email;
    },
    setUsername(state, email) {
      state.username = email;
    },
    setUserAvailable(state, isAvailable) {
      state.isUserAvailable = isAvailable;
    },
  },
  actions: {
    async getCurrentUser({ commit, dispatch }) {
      await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user`, {
        credentials: 'include',
      })
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              if (data) {
                commit('setUserAvailable', true);
                commit('setUserEmail', data.userName);

                if (
                  !data.userName &&
                  data.suggestions &&
                  data.suggestions.userName
                ) {
                  commit('setUserEmail', data.suggestions.userName);
                }

                if (data.userName) {
                  commit('mailManager/setEmailIsValidated', true, {
                    root: true,
                  });
                  commit('fidoManager/setIsFidoActive', true, { root: true });
                }
              }
            });
          }
        })
        .catch(() => {
          dispatch(
            'notifications/addNotification',
            {
              title: 'generic.error-title',
              type: 'error',
              content: 'generic.error-intro',
            },
            { root: true },
          );
        });
    },
  },
  getters: {},
};

import config from './modules/config';
import features from './modules/features';
import mailManager from './modules/mail-manager';
import fidoManager from './modules/fido-manager';
import studentAccountManager from './modules/student-account-manager';
import notifications from './modules/notifications';
import user from './modules/user';

const { fetch: origFetch } = window;

window.fetch = async (...args) => {
  const response = await origFetch(...args);

  if (window.location.pathname !== "/logout" && (response.status === 401 || response.status === 403)) {
    window.location.replace('/user/login');
  }

  return response;
};

export default {
  modules: {
    config,
    features,
    mailManager,
    fidoManager,
    studentAccountManager,
    notifications,
    user,
  },
};

<template>
  <div
    v-if="loaded"
    class="av-student-account-manager"
  >
    <vl-title tag-name="h3">
      {{ $t('student-account-manager.title') }}
    </vl-title>
    <vl-grid mod-stacked>
      <vl-column
        width-l="8"
        width-m="6"
        width-s="6"
        width-xs="12"
      >
        <vl-grid mod-stacked>
          <vl-column>
            <div class="vl-form__label">
              {{ $t('student-account-manager.username') }}
            </div>
            <div>
              {{ studentDetails.username }}
            </div>
          </vl-column>
          <vl-column>
            <vl-form-validation-observer
              v-slot="{ handleSubmit }"
              slim
            >
              <form @submit.prevent="handleSubmit(saveNewEmail)">
                <vl-grid mod-stacked>
                  <vl-column>
                    <label
                      v-if="hasEmail"
                      class="vl-form__label"
                      for="mail-input"
                    >{{ $t('student-account-manager.email') }}
                    </label>
                    <div
                      v-if="isEmailValidated && hasEmail"
                      class="av-estudent-account-manager__label-annotation"
                    >
                      {{ $t('student-account-manager.validated') }}
                    </div>

                    <vl-input-field
                      v-if="hasEmail"
                      id="mail-input"
                      v-model="studentDetails.email"
                      type="input"
                      name="mail-input"
                      disabled="true"
                      mod-block
                    />
                  </vl-column>
                  <vl-column v-if="changeEmailInProgress">
                    <label
                      class="vl-form__label"
                      for="new-mail-input"
                    >{{ $t('student-account-manager.new-email') }}
                    </label>
                    <vl-form-validation
                      v-slot="{ errors: fieldErrors, classes }"
                      rules="required|email"
                      name="email"
                    >
                      <vl-form-message-error v-if="fieldErrors">
                        {{ fieldErrors[0] }}
                      </vl-form-message-error>
                      <vl-input-field
                        id="new-mail-input"
                        v-model="newEmailInput"
                        :class="classes"
                        type="input"
                        name="mail-input"
                        mod-block
                      />
                    </vl-form-validation>
                  </vl-column>
                  <template v-if="isEmailValidated && !changeEmailInProgress && hasEmail">
                    <template v-if="canChangeEmail">
                      <vl-column>
                        <vl-action-group>
                          <vl-button
                            type="button"
                            @click="changeEmail"
                          >
                            {{ $t('student-account-manager.change-email') }}
                          </vl-button>
                          <vl-button
                            mod-tertiary
                            type="button"
                            @click="deleteEmailWithConfirm"
                          >
                            {{ $t('student-account-manager.delete-email') }}
                          </vl-button>
                        </vl-action-group>
                      </vl-column>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="!isEmailValidated && !changeEmailInProgress">
                      <vl-column>
                        <vl-alert
                          v-if="hasEmail"
                          icon="warning"
                          :title="$t('student-account-manager.validation-mail-sent')"
                          mod-naked
                          role="alertdialog"
                        >
                          <p class="vl-u-mark--accent">
                            {{ $t('student-account-manager.validation-mail-sent-intro', {email: studentDetails.email}) }}
                          </p>
                        </vl-alert>
                        <vl-alert
                          v-else
                          icon="warning"
                          :title="$t('student-account-manager.no-mail')"
                          mod-naked
                          role="alertdialog"
                        >
                          <p class="vl-u-mark--accent">
                            {{ $t('student-account-manager.no-mail-intro') }}
                          </p>
                        </vl-alert>
                      </vl-column>
                      <vl-column>
                        <vl-action-group class="vl-action-group--collapse--m">
                          <vl-button
                            v-if="!emailResent && hasEmail"
                            type="button"
                            @click="resendEmail"
                          >
                            {{ $t('student-account-manager.resend-email') }}
                          </vl-button>
                          <vl-button
                            v-else-if="hasEmail"
                            mod-disabled
                            type="button"
                          >
                            {{ $t('student-account-manager.email-resent') }}
                          </vl-button>
                          <vl-button
                            v-if="canChangeEmail"
                            mod-secondary
                            type="button"
                            @click="changeEmail"
                          >
                            {{ $t('student-account-manager.change-email') }}
                          </vl-button>
                          <vl-button
                            v-if="hasEmail && canChangeEmail"
                            mod-tertiary
                            type="button"
                            @click="deleteEmailWithConfirm"
                          >
                            {{ $t('student-account-manager.delete-email') }}
                          </vl-button>
                        </vl-action-group>
                      </vl-column>
                    </template>
                    <template v-else>
                      <vl-column>
                        <vl-alert
                          icon="warning"
                          :title="$t('student-account-manager.validate')"
                          mod-naked
                          mod-warning
                          role="alertdialog"
                        >
                          <p class="vl-u-mark--warning">
                            {{ $t('student-account-manager.validate-intro') }}
                          </p>
                        </vl-alert>
                      </vl-column>
                      <vl-column>
                        <vl-action-group>
                          <vl-button>
                            {{ $t('student-account-manager.start-validation') }}
                          </vl-button>

                          <vl-button
                            v-if="isEmailValidated || studentDetails.email"
                            mod-secondary
                            @click="changeEmailInProgress = false"
                          >
                            {{ $t('generic.cancel') }}
                          </vl-button>
                        </vl-action-group>
                      </vl-column>
                    </template>
                  </template>
                </vl-grid>
              </form>
            </vl-form-validation-observer>
          </vl-column>
          <template v-if="canChangePassword">
            <vl-column>
              <div class="vl-form__label">
                {{ $t('student-account-manager.password') }}
              </div>
              <div>{{ $t('student-account-manager.password-optional') }}</div>
            </vl-column>
            <vl-column v-if="!isPasswordUpdating">
              <vl-button
                mod-secondary
                type="buton"
                @click="startPasswordUpdate"
              >
                {{ $t('student-account-manager.change-password') }}
              </vl-button>
            </vl-column>
          </template>
          <template v-if="isPasswordUpdating">
            <vl-column>
              <av-password-field
                id="new-password-input"
                v-model="newPassword"
                :label="$t('student-account-manager.new-password')"
              />
            </vl-column>
            <vl-column>
              <div
                id="rulesDiv"
                style="max-width: 500px;"
              />
            </vl-column>
            <vl-column>
              <vl-action-group class="vl-action-group--collapse--m">
                <vl-button
                  :mod-disabled="!isPasswordValid"
                  @click="saveNewPassword"
                >
                  {{ $t('student-account-manager.save-new-password') }}
                </vl-button>

                <vl-button
                  mod-secondary
                  @click="cancelPasswordUpdate"
                >
                  {{ $t('generic.cancel') }}
                </vl-button>
              </vl-action-group>
            </vl-column>
          </template>
        </vl-grid>
      </vl-column>
      <vl-column
        width-l="4"
        width-m="6"
        width-s="6"
        width-xs="12"
      >
        <vl-info-tile
          tag-name="div"
          :title="$t('student-account-manager.help-title')"
        >
          <vl-badge
            slot="badge"
            icon="question-mark"
            mod-accent
          />
          {{ $t('student-account-manager.help-text') }}
        </vl-info-tile>
      </vl-column>
    </vl-grid>
  </div>
</template>

<script>
import {
  VlActionGroup,
  VlBadge,
  VlButton,
  VlInfoTile,
  VlTitle,
  VlFormMessageError,
  VlFormValidation,
  VlAlert,
  VlFormValidationObserver,
  VlInputField,
} from '@govflanders/vl-ui-vue-components'
import {mapActions, mapState} from "vuex";
import AvPasswordField from "@/components/atoms/AvPasswordField";

import {PasswordValidator } from "password-validator/dist/password-validator";
import { RulesResult } from "password-validator/dist/rules-result";

export default {
  name: "AvstudentAccountManager",
  components: {
    VlTitle,
    VlButton,
    AvPasswordField,

    VlInfoTile,
    VlBadge,
    VlActionGroup,
    VlFormValidation,
    VlFormMessageError,
    VlAlert,
    VlInputField,
    VlFormValidationObserver
  },
  data() {
    return {
      username: 'Lennie.lister',
      emailInput: 'joa@joa.com',
      newEmailInput: null,
      changeEmailInProgress: false,
      emailResent: false,
      password: 'optioneel',
      newPassword: '',
      isPasswordValid: false,
      loaded: false,
    }
  },
  computed: {
    ...mapState({
      studentDetails: st => st.studentAccountManager.studentDetails,
      passwordRules: st => st.studentAccountManager.setStudentPasswordRules,
      isEmailValidated: st => st.studentAccountManager.studentDetails.email_verified === true || false,
      hasEmail: st => st.studentAccountManager.studentDetails.email !== "" || false,
      isPasswordUpdating: st => st.studentAccountManager.isPasswordUpdating || false,
      canChangePassword: st => st.studentAccountManager.studentDetails.password_update_allowed !== "forbidden" || false,
      canChangeEmail: st => st.studentAccountManager.studentDetails.email_update_allowed !== "forbidden" || false,
    }),
    email: {
      get() {
        return this.emailInput
      },
      set(value) {
        if (value !== this.emailInput) {
          this.emailInput = value;
        }
      }
    },
    newEmail: {
      get() {
        return this.newEmailInput
      },
      set(value) {
        if (value !== this.newEmailInput) {
          this.newEmailInput = value;
        }
      }
    }
  },
  // when this.newPassword changes, the password rules are validated
  watch: {
    newPassword() {
      this.isPasswordValid = this.validatePassword();
    },
  },
  async mounted() {
    await this.loadStudentDetails();
    this.loaded = true;
  },
  methods: {
    ...mapActions({
      loadStudentDetails: 'studentAccountManager/fetchStudentDetails',
      loadStudentPasswordRules: 'studentAccountManager/fechtPasswordRules',
      savePassword: 'studentAccountManager/saveNewPassword',
      saveEmail: 'studentAccountManager/saveNewEmail',
      resendValidationEmail: 'studentAccountManager/resendValidationEmail',
      deleteEmail: 'studentAccountManager/deleteEmail',
      startPasswordUpdate: 'studentAccountManager/startPasswordUpdate',
      cancelPasswordUpdate: 'studentAccountManager/cancelPasswordUpdate',
    }),
    validatePassword() {
      const validator = new PasswordValidator(this.passwordRules.password, this.studentDetails.username)
      const response = validator.validatePassword(this.newPassword)
      const rulesDiv = document.getElementById("rulesDiv")
      console.log(rulesDiv)

      const rulesResult = new RulesResult(rulesDiv, [
        ["label1", "Voldoet niet aan de regels"],
        ["label2", "Nog te kort"],
        ["label3", "OK"],
        ["label4", "Goed bezig..."],
        ["label5", "Goed zo!"],
        ["label6", "Sterk bezig"],
      ], [
        ["required-rules", "Kies een wachtwoord"],
        ["optional-rules", "Wachtwoord tips"],
      ])

      rulesResult.loadResult(this.newPassword, response);

      return response.valid || false;
    },
    saveNewPassword() {
      const validator = new PasswordValidator(this.passwordRules.password, this.studentDetails.username)
      const response = validator.validatePassword(this.newPassword)
      const rulesDiv = document.getElementById("rulesDiv")

      const rulesResult = new RulesResult(rulesDiv, [
        ["label1", "Voldoet niet aan de regels"],
        ["label2", "Nog te kort"],
        ["label3", "OK"],
        ["label4", "Goed bezig..."],
        ["label5", "Goed zo!"],
        ["label6", "Sterk bezig"],
      ], [
        ["required-rules", "Kies een wachtwoord"],
        ["optional-rules", "Wachtwoord tips"],
      ])

      rulesResult.loadResult(this.newPassword, response);
      if (response.valid) {
        this.savePassword(this.newPassword);
      }
    },
    changeEmail() {
      this.newEmail = null;
      this.changeEmailInProgress = true;
    },
    resendEmail() {
      this.resendValidationEmail();

      this.emailResent = true;
      setTimeout(() => this.emailResent = false, 30000);
    },
    saveNewEmail(errors) {
      if (!errors || !errors.email) {
        this.saveEmail(this.newEmail);
        this.changeEmailInProgress = false;
      }
    },
    deleteEmailWithConfirm() {
      if (confirm(this.$t('student-account-manager.delete-email-confirmation'))) {
        this.deleteEmail(this.email);
      }
    },
  }
}
</script>

<style scoped>
.vl-checkbox--switch__wrapper {
  margin-top: 7px;
}

.vl-input-field {
  max-width: 500px;
  width: 100%;
}

.vl-button {
  margin-right: 20px;
}


</style>

<style lang="scss">
:root {
  --vl-theme-primary-color: #0055cc !important;
  --vl-theme-primary-color-60: #0055cc !important;
  --vl-theme-primary-color-70: #0055cc !important;
}

.vl-badge--icon .vl-badge__icon, .vl-badge--icon .vl-badge__image-icon img {
  color: white !important;
  fill: white !important;
}
.vl-alert__title .vl-u-mark--accent, .vl-u-mark--accent {
  box-shadow: inset 0 -10px 0 0 rgba(#0055cc, 0.1) !important;
}
.vl-badge {
  background-color: #0055cc !important;
}
.vl-vi {
  margin-right: 5px !important;
}
</style>

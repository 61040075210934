export default {
  namespaced: true,
  state: () => ({
    isEmailValidated: false,
    isEmailValidationOngoing: false,
    toBeValidatedEmail: null,
  }),
  mutations: {
    setIsEmailValidationOngoing(state, value) {
      state.isEmailValidationOngoing = value;
    },
    setToBeValidatedEmail(state, value) {
      state.toBeValidatedEmail = value;
    },
    setEmailIsValidated(state, value) {
      state.isEmailValidated = value;
    },
  },
  actions: {
    startEmailValidation({ commit, dispatch }, email) {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/user`, {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userName: email }),
      })
        .then(response => {
          if (response.ok) {
            commit('setIsEmailValidationOngoing', true);
            commit('setToBeValidatedEmail', email);
          }
        })
        .catch(() => {
          dispatch(
            'notifications/addNotification',
            {
              title: 'generic.error-title',
              type: 'error',
              content: 'generic.error-intro',
            },
            { root: true },
          );
        });
    },
    resendValidationEmail({ dispatch }) {
      dispatch('startEmailValidation');
    },
    pauseEmailValidation({ commit }) {
      commit('setIsEmailValidationOngoing', false);
      commit('setToBeValidatedEmail', null);
    },
  },
  getters: {},
};

<template>
  <vl-layout class="av-home">
    <vl-region class="av-home__header">
      <vl-grid>
        <vl-column
          width-l="9"
          width-m="9"
          width-s="12"
        >
          <vl-title tag-name="h1">
            <vl-badge
              icon="lock"
              mod-medium
              mod-border
            />
            {{ $t('home.header-title') }}
          </vl-title>
        </vl-column>
        <vl-column
          width-l="9"
          width-m="9"
          width-s="12"
        >
          <vl-annotation>
            {{ $t('home.header-logged-out') }} <a href="/">{{ $t('home.header-login') }}</a>
          </vl-annotation>
        </vl-column>
      </vl-grid>
    </vl-region>
  </vl-layout>
</template>

<script>
import {VlAnnotation, VlBadge, VlTitle} from '@govflanders/vl-ui-vue-components'

export default {
  name: 'Logout',
  components: {
    VlBadge,
    VlTitle,
    VlAnnotation
  },
};
</script>

<style scoped lang="scss">
.av-home__header {
  .vl-title {
    display: flex;
    align-items: center;

    .vl-badge {
      margin-right: 20px;
    }
  }
}
</style>

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    setList(state, value) {
      state.list = value;
    },
  },
  actions: {
    addNotification({ commit, state, dispatch }, notification) {
      if (!state.list.find(el => el.key === notification.key)) {
        commit('setList', [...state.list, notification]);

        if (notification.hideAfter) {
          setTimeout(
            () => dispatch('removeNotification', notification),
            notification.hideAfter,
          );
        }
      }
    },
    removeNotification({ commit, state }, notification) {
      commit('setList', [
        ...state.list.filter(el => el.title !== notification.title),
      ]);
    },
    clearNotificationsForKey({ commit, state }, key) {
      commit('setList', [...state.list.filter(el => el.key !== key)]);
    },
  },
  getters: {},
};

<template>
  <div class="cm-app">
    <!-- Global header -->
    <div
      id="global-header-placeholder"
      class="vl-global-header-placeholder"
    />
    <!-- END Global header -->

    <div
      v-if="isUserAvailable"
      class="cm-app__router-outlet"
    >
      <vl-page>
        <header class="vl-functional-header">
          <vl-layout>
            <div class="vl-functional-header__row">
              <div class="vl-functional-header__content">
                <h1 class="vl-title">
                  <a
                    class="vl-functional-header__title"
                    href="#"
                  >{{
                    $t('header.project-title')
                  }}</a>
                </h1>
              </div>
            </div>
            <div class="vl-functional-header__sub">
              <ul class="vl-functional-header__sub__actions">
                <li class="vl-functional-header__sub__action">
                  {{ $t('header.title') }}
                </li>
              </ul>
            </div>
          </vl-layout>
        </header>

        <router-view />
      </vl-page>
    </div>
    <div
      v-if="currentRouteName === 'Logout'"
      class="cm-app__router-outlet"
    >
      <vl-page>
        <header class="vl-functional-header">
          <vl-layout>
            <div class="vl-functional-header__row">
              <div class="vl-functional-header__content">
                <h1 class="vl-title">
                  <a
                    class="vl-functional-header__title"
                    href="#"
                  >{{
                    $t('header.project-title')
                  }}</a>
                </h1>
              </div>
            </div>
            <div class="vl-functional-header__sub">
              <ul class="vl-functional-header__sub__actions">
                <li class="vl-functional-header__sub__action">
                  {{ $t('header.title') }}
                </li>
              </ul>
            </div>
          </vl-layout>
        </header>

        <router-view />
      </vl-page>
    </div>

    <!-- Global footer -->
    <div id="global-footer-placeholder" />

    <av-toaster />
    <!-- END Global footer -->
  </div>
</template>

<script>
import '@govflanders/vl-widget-polyfill';
import AvToaster from "@/components/molecules/AvToaster";
import * as widgets from '@govflanders/vl-widget-client';
import {mapState} from "vuex";

export default {
  name: 'App',
  components: {AvToaster},
  data() {
    return {
      isFresh: false,
      loaded: false,
      isCreated: false,
    };
  },
  computed: {
    ...mapState(
        {
          isUserAvailable: st => st.user.isUserAvailable,
          globalHeader: st => st.config.globalHeader,
          globalFooter: st => st.config.globalFooter,
          isConfigLoaded: st => st.config.isConfigLoaded,
        }
    ),
    currentRouteName() {
      return this.$route.name;
    }
  },
  watch: {
    isConfigLoaded: {
      handler: function (val) {
        if (val && this.loaded) {
          this.mountWidgets(this.currentRouteName !== 'Logout');
        }
      },
    },
  },
  mounted() {
    if (this.isConfigLoaded) {
      this.mountWidgets(this.currentRouteName !== 'Logout');
    }
    this.loaded = true;
  },
  async created() {
    await this.$store.dispatch('config/loadConfig');
    await this.$store.dispatch('user/getCurrentUser');
    this.isCreated = true;
  },
  methods: {
    async mountWidgets(active) {
      try {
        const globalHeader = this.globalHeader || ""
        const globalFooter = this.globalFooter || ""
        console.log(globalHeader, globalFooter)

        await widgets
            .bootstrap(
              globalHeader,
            )
            .then((widget) => {
              if (!document.getElementById('headerWrapperElement')) {
                const headerWrapperElement = document.createElement('div');
                headerWrapperElement.id = 'headerWrapperElement';
                const parentContainer = document.getElementById(
                    'global-header-placeholder',
                );
                widget.setMountElement(headerWrapperElement);
                widget.mount();
                parentContainer.insertBefore(
                    headerWrapperElement,
                    parentContainer.childNodes[0] || null,
                );
              }

              widget.getExtension('citizen_profile.session').then(function (session) {
                // Build a list of event names which should be used for activity tracking.
                var eventNames = [
                  'mousedown',
                  'mousemove',
                  'mousewheel',
                  'DOMMouseScroll',
                  'scroll',
                  'wheel',
                  'keydown',
                  'keypress',
                  'touchmove',
                  'touchstart'
                ];
                // Iterate through the events names.
                for (var i = 0; i < eventNames.length; i++) {
                  // Register our event handler given event name.
                  window.addEventListener(eventNames[i], () => session.extend());
                }
              });

              widget.getExtension('citizen_profile.session').then(function (session) {
                session.configure({
                  active: active,
                  endpoints: {
                    loginUrl: '/user/login',
                    loginRedirectUrl: '/',
                    logoutUrl: '/user/logout'
                  }
                });
              });
            });

        await widgets
            .bootstrap(
              globalFooter,
            )
            .then((widget) => {
              if (!document.getElementById('footerWrapperElement')) {
                const footerWrapperElement = document.createElement('div');
                footerWrapperElement.id = 'footerWrapperElement';
                widget.setMountElement(footerWrapperElement);
                widget.mount();
                document
                    .getElementById('global-footer-placeholder')
                    .appendChild(footerWrapperElement);
              }
            });

        this.widgetGenerated = true;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('[Front-end wp-mx-widgets.js] - generateWidgets', error);
      }
    },
  },
};
</script>

<style lang="scss">
.cm-app {
  height: 100%;
}

.cm-app__router-outlet {
  min-height: calc(100% - 189px);
}
</style>

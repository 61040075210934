export default {
  namespaced: true,
  state: () => ({
    isEmailManagerEnabled: false,
    isLeerIDManagerEnabled: false,
    isMFAEmailEnabled: false,
    isMFAAppEnabled: false,
  }),
  mutations: {
    setIsEmailManagerEnabled(state, value) {
      state.isEmailManagerEnabled = value;
    },
    setIsLeerIDManagerEnabled(state, value) {
      state.isLeerIDManagerEnabled = value;
    },
    setIsMFAEmailEnabled(state, value) {
      state.isMFAEmailEnabled = value;
    },
    setIsMFAAppEnabled(state, value) {
      state.isMFAAppEnabled = value;
    }
  },
  actions: {
    activateLeerIDManager({ commit }) {
      commit('setIsEmailManagerEnabled', true);
    },
    deactivateLeerIDManager({ commit }) {
      commit('setIsEmailManagerEnabled', false);
    },
    activateMFAEmail({ commit }) {
      commit('setIsMFAEmailEnabled', true);
    },
    deactivateMFAEmail({ commit }) {
      commit('setIsMFAEmailEnabled', false);
    },
    activateMFAApp({ commit }) {
      commit('setIsMFAAppEnabled', true);
    },
    deactivateMFAApp({ commit }) {
      commit('setIsMFAAppEnabled', false);
    }
  },
  getters: {},
};

<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <vl-alert
      v-if="isFidoUnsupported"
      icon="alert-circle"
      :title="$t('fido-manager.browser-check.error.title')"
      mod-error
      role="alertdialog"
    >
      <div v-html="$t('fido-manager.browser-check.error.content', {browser: currentBrowser})" />
    </vl-alert>
    <!--eslint-enable-->
    <vl-alert
      v-else-if="isFidoWithPlatformAuthUnsupported"
      icon="alert-triangle"
      :title="$t('fido-manager.browser-check.warning.title')"
      mod-warning
      role="alertdialog"
    >
      <div v-html="$t('fido-manager.browser-check.warning.content', {browser: currentBrowser})" />
    </vl-alert>
  </div>
</template>

<script>
import {VlAlert} from '@govflanders/vl-ui-vue-components';
import AvBrowserCheckMixin from "@/components/mixins/av-browser-check-mixin";

export default {
  name: "AvBrowserCheckCard",
  components: {
    VlAlert
  },
  mixins: [AvBrowserCheckMixin],
  computed: {
    currentBrowser() {
      const userAgent = navigator.userAgent;
      const browser = this.getBrowser(userAgent);
      const version = this.getVersion(userAgent, browser);
      return `${this.getBrowser(userAgent)}${version ? ' ' + version : ''}`;
    }
  },
  methods: {
    getBrowser(userAgent) {
      if (userAgent.match(/chrome|chromium|crios/i)) {
        return "Chrome";
      } else if (userAgent.match(/firefox|fxios/i)) {
        return "Firefox";
      } else if (userAgent.match(/safari/i)) {
        return "Safari";
      } else if (userAgent.match(/opr\//i)) {
        return "Opera";
      } else if (userAgent.match(/edg/i)) {
        return "Edge";
      }

      return undefined;
    },
    getVersion(userAgent, browser) {
      return browser ? userAgent.replace(new RegExp(`^.*${browser}/([^ ]*).*$`), '$1') : undefined;
    }
  }
}
</script>

<style scoped>

</style>

export default {
  namespaced: true,
  state: () => ({
    globalHeader: null,
    globalFooter: null,
    isConfigLoaded: false,
  }),
  mutations: {
    setGlobalHeader(state, globalHeader) {
      state.globalHeader = globalHeader;
    },
    setGlobalFooter(state, globalFooter) {
      state.globalFooter = globalFooter;
    },
    setIsConfigLoaded(state, value) {
      state.isConfigLoaded = value;
    }
  },
  actions: {
    async loadConfig({ commit, dispatch }) {
      await fetch(`config.json`)
        .then(response => {
          if (response.ok) {
            response.json().then(data => {
              if (data) {
                commit('setGlobalHeader', data.globalHeader || null);
                commit('setGlobalFooter', data.globalFooter || null);

                if (data.features) {
                  if (data.features.emailManager) {
                    commit('features/setIsEmailManagerEnabled', data.features.emailManager.enabled || false, { root: true });
                  }
                  if (data.features.leerIDManager) {
                    commit('features/setIsLeerIDManagerEnabled', data.features.leerIDManager.enabled || false, { root: true });
                  }
                  if (data.features.mfaEmail) {
                    commit('features/setIsMFAEmailEnabled', data.features.mfaEmail.enabled || false, { root: true });
                  }
                  if (data.features.mfaApp) {
                    commit('features/setIsMFAAppEnabled', data.features.mfaApp.enabled || false, { root: true });
                  }
                }

                if (data.userName) {
                  commit('mailManager/setEmailIsValidated', true, {
                    root: true,
                  });
                  commit('fidoManager/setIsFidoActive', true, { root: true });
                }

                commit('setIsConfigLoaded', true);
              }
            });
          }
        })
        .catch(() => {
          dispatch(
            'notifications/addNotification',
            {
              title: 'generic.error-title',
              type: 'error',
              content: 'generic.error-intro',
            },
            { root: true },
          );
        });
    },
  },
  getters: {},
};

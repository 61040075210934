<template>
  <vl-layout class="av-home">
    <vl-region class="av-home__header">
      <vl-grid>
        <vl-column
          width-l="9"
          width-m="9"
          width-s="12"
        >
          <vl-title tag-name="h1">
            <vl-badge
              icon="lock"
              mod-medium
              mod-border
            />
            {{ $t('home.header-title') }}
          </vl-title>
        </vl-column>
        <vl-column
          width-l="9"
          width-m="9"
          width-s="12"
        >
          <vl-annotation>
            {{ $t('home.header-annotation') }}
          </vl-annotation>
        </vl-column>
      </vl-grid>
    </vl-region>
    <vl-region>
      <av-email-manager v-if="isEmailManagerEnabled" />
    </vl-region>
    <vl-region>
      <av-student-account-manager v-if="isLeerIDManagerEnabled" />
    </vl-region>
    <vl-region>
      <av-multi-factor-manager v-if="isMultiFactorEnabled" />
    </vl-region>
    <vl-region>
      <av-fido-manager />
    </vl-region>
  </vl-layout>
</template>

<script>
import {VlAnnotation, VlBadge, VlTitle} from '@govflanders/vl-ui-vue-components'
import AvEmailManager from "@/components/molecules/AvEmailManager";
import AvFidoManager from "@/components/organisms/AvFidoManager";
import AvStudentAccountManager from '@/components/molecules/AvStudentAccountManager.vue';
import AvMultiFactorManager from '@/components/molecules/AvMultiFactorManager.vue';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    AvFidoManager,
    AvEmailManager,
    AvStudentAccountManager,
    AvMultiFactorManager,
    VlBadge,
    VlTitle,
    VlAnnotation
  },
  computed: {
    ...mapState(
      {
        isLeerIDManagerEnabled: st => st.features.isLeerIDManagerEnabled,
        isEmailManagerEnabled: st => st.features.isEmailManagerEnabled,
        isMultiFactorEnabled: st => st.features.isMFAEmailEnabled || st.features.isMFAAppEnabled,
      }
    )
  },
};
</script>

<style scoped lang="scss">
.av-home__header {
  .vl-title {
    display: flex;
    align-items: center;

    .vl-badge {
      margin-right: 20px;
    }
  }
}
</style>

<template>
  <vl-grid mod-stacked>
    <vl-column v-if="registeredDevices.length === 0">
      <vl-alert
        icon="warning"
        :title="$t('fido-manager.no-devices-registered')"
        mod-warning
        role="alertdialog"
      >
        <p>
          {{ $t('fido-manager.no-devices-registered-intro') }}
        </p>
        <template slot="actions">
          <vl-button v-vl-modal-toggle="'register-modal'">
            {{ $t('fido-manager.register-this-device') }}
          </vl-button>
        </template>
      </vl-alert>
    </vl-column>
    <template v-else>
      <vl-column>
        <vl-form-message-label>
          {{ $t('fido-manager.use-fido-automatically') }}
        </vl-form-message-label>
        <label
          class="vl-checkbox"
          for="fido-automatic-check"
        >
          <input
            id="fido-automatic-check"
            v-model="isAutomaticUseAllowed"
            class="vl-checkbox__toggle"
            type="checkbox"
            name="fido-automatic-check"
          >
          <span class="vl-checkbox__label">
            <i
              class="vl-checkbox__box"
              aria-hidden="true"
            />
            {{ $t('fido-manager.use-fido-automatically-check') }}
          </span>
        </label>
      </vl-column>
      <vl-column>
        <vl-form-message-label>
          {{ $t('fido-manager.devices') }}
        </vl-form-message-label>

        <div class="av-fido-device-manager__list">
          <av-device-card
            v-for="device of registeredDevices"
            :key="device.id"
            :device="device"
          />
        </div>
      </vl-column>
      <vl-column>
        <vl-grid mod-stacked-small>
          <vl-column>
            <vl-form-message-label>
              {{ $t('fido-manager.add-device') }}
            </vl-form-message-label>
          </vl-column>
          <vl-column>
            <vl-button v-vl-modal-toggle="'register-modal'">
              {{ $t('fido-manager.register-this-device') }}
            </vl-button>
          </vl-column>
        </vl-grid>
      </vl-column>
      <vl-column>
        <av-fido-test />
      </vl-column>
    </template>
    <av-register-device-modal />
  </vl-grid>
</template>

<script>
import {VlAlert, VlButton, VlFormMessageLabel} from '@govflanders/vl-ui-vue-components'
import {mapActions, mapState} from "vuex";
import AvDeviceCard from "@/components/atoms/AvDeviceCard";
import AvFidoTest from "@/components/atoms/AvFidoTest";
import AvRegisterDeviceModal from "@/components/molecules/AvRegisterDeviceModal";

export default {
  name: "AvFidoDeviceManager",
  components: {
    AvRegisterDeviceModal,
    AvDeviceCard,
    VlButton,
    VlAlert,
    VlFormMessageLabel,
    AvFidoTest,
  },
  computed: {
    ...mapState({
      registeredDevices: st => st.fidoManager.registeredDevices,
      isAutomaticFidoUseAllowed: st => st.fidoManager.isAutomaticFidoUseAllowed
    }),
    isAutomaticUseAllowed: {
      get() {
        return this.isAutomaticFidoUseAllowed;
      },
      set(value) {
        this.updateAutomaticUsePermission(value);
      }
    }
  },
  created() {
    this.getDevices();
  },
  methods: {
    ...mapActions({
      getDevices: 'fidoManager/fetchRegisteredDevices',
      updateAutomaticUsePermission: 'fidoManager/updateAutomaticUsePermission',
    }),
  },
}
</script>

<style scoped lang="scss">
.av-fido-device-manager__list {
  display: flex;
  flex-wrap: wrap;

  * {
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
</style>

const handleGenericError = (error, dispatch, key) => {
  console.error(error);

  if (!error || error.name !== 'NotAllowedError') {
    dispatch(
      'notifications/addNotification',
      {
        title: 'generic.error-title',
        key,
        type: 'error',
        content: 'generic.error-intro',
      },
      { root: true },
    );
  }
};

export default {
  namespaced: true,
  state: () => ({
    studentDetails: {},
    setStudentPasswordRules: {},
    isPasswordUpdating: false,
    isEmailUpdateSuccessful: false,
  }),
  mutations: {
    setStudentDetails(state, value) {
      state.studentDetails = value;
    },
    setStudentPasswordRules(state, value) {
      state.setStudentPasswordRules = value;
    },
    setPasswordUpdating(state, value) {
      state.isPasswordUpdating = value;
    },
    setEmailUpdateSuccess(state, value) {
      state.isEmailUpdateSuccessful = value;
    },
  },
  actions: {
    startPasswordUpdate({ commit, dispatch }) {
      commit('setPasswordUpdating', true);
      dispatch('fetchPasswordRules');
    },
    cancelPasswordUpdate({ commit }) {
      commit('setPasswordUpdating', false);
    },
    async fetchStudentDetails({ commit, dispatch }) {
      dispatch('notifications/clearNotificationsForKey', 'leerid-details', {
        root: true,
      });

      await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/v1/plugin/leerid/user`, {
        credentials: 'include',
        headers: {
          'Accept-Language': 'nl',
        }
      })
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(data => {
                if (data) {
                  commit('setStudentDetails', data);
                  commit('user/setUserEmail', data.email, { root: true });
                  commit('user/setUsername', data.username, { root: true });
                }
              })
              .catch(error =>
                handleGenericError(error, dispatch, 'leerid-details'),
              );
          }
        })
        .catch(error => handleGenericError(error, dispatch, 'leerid-details'));
    },
    async fetchPasswordRules({ commit, dispatch }) {
      await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/v1/plugin/leerid/user/rules`, {
        credentials: 'include',
        headers: {
          'Accept-Language': 'nl',
        }
      })
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(data => {
                if (data) {
                  commit('setStudentPasswordRules', data);
                }
              })
              .catch(error =>
                handleGenericError(error, dispatch, 'leerid-details'),
              );
          }
        })
        .catch(error => handleGenericError(error, dispatch, 'leerid-details'));
    },
    saveNewPassword({ commit, dispatch }, password) {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/v1/plugin/leerid/user`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Accept-Language': 'nl',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: password,
        }),
      })
        .then(response => {
          if (response.ok) {
            commit('setPasswordUpdating', false);
          }
        })
        .catch(() => {
          dispatch(
            'notifications/addNotification',
            {
              title: 'generic.error-title',
              type: 'error',
              content: 'generic.error-intro',
            },
            { root: true },
          );
        });
    },
    async resendValidationEmail({ dispatch }) {
      dispatch('notifications/clearNotificationsForKey', 'leerid-resend-validation', {
        root: true,
      });

      await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/v1/plugin/leerid/user/resendVerificationEmail`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Accept-Language': 'nl',
        }
      })
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(data => {
                if (data && data.annotations && data.annotations.length > 0) {
                      console.log(data)
                      dispatch(
                        'notifications/addNotification',
                        {
                          title: data.annotations[0].value,
                          type: 'success',
                          content: data.annotations[1]?.value,
                        },
                        { root: true },
                      );
                    }
                  }).catch(error =>
                handleGenericError(error, dispatch, 'leerid-resend-validation'),
              );
          }
        })
        .catch(error => handleGenericError(error, dispatch, 'leerid-resend-validation'));
    },
    async deleteEmail({ dispatch }) {
      dispatch('notifications/clearNotificationsForKey', 'leerid-delete-email', {
        root: true,
      });

      await fetch(`${process.env.VUE_APP_API_BASE_URL}/api/v1/plugin/leerid/user`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Accept-Language': 'nl',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: "DELETE",
        }),
      })
        .then(response => {
          if (response.ok) {
            response
              .json()
              .then(data => {
                if (data) {
                    if (data && data.annotations && data.annotations.length > 0) {
                      console.log(data)
                      dispatch(
                        'notifications/addNotification',
                        {
                          title: data.annotations[0].value,
                          type: 'success',
                          content: data.annotations[1]?.value,
                        },
                        { root: true },
                      );
                      dispatch('fetchStudentDetails');
                    }
                }
              })
              .catch(error =>
                handleGenericError(error, dispatch, 'leerid-delete-email'),
              );
          }
        })
        .catch(error => handleGenericError(error, dispatch, 'leerid-delete-email'));
    },
    saveNewEmail({ commit, dispatch }, email) {
      fetch(`${process.env.VUE_APP_API_BASE_URL}/api/v1/plugin/leerid/user`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Accept-Language': 'nl',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      })
        .then(response => {
          if (response.ok) {
            commit('setEmailUpdateSuccess', true);
            response.json().then(data => {
              if (data && data.annotations && data.annotations.length > 0) {
                console.log(data)
                dispatch(
                  'notifications/addNotification',
                  {
                    title: data.annotations[0].value,
                    type: data.type === 'FOUT' ? 'error' : 'success',
                    content: data.annotations[1]?.value,
                  },
                  { root: true },
                );
                dispatch('fetchStudentDetails');
              }
            });
          }
        })
        .catch(() => {
          dispatch(
            'notifications/addNotification',
            {
              title: 'generic.error-title',
              type: 'error',
              content: 'generic.error-intro',
            },
            { root: true },
          );
        });
    },
  },
  getters: {},
};
